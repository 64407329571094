import * as React from 'react'
import { LayoutBootstrap } from '../components/layoutBootstrap'
import { Container, Row, Col } from 'react-bootstrap'
import "../styles/styles.css"
import { StaticImage } from 'gatsby-plugin-image'
import { useContext } from 'react'
import { LanguageContext } from '../components/language'
import { T } from '../translation/translate'



const AssocationPage = () => {
    const {lang, setLang} = useContext(LanguageContext);

return(
    
    <LayoutBootstrap>

            <Container fluid style={{backgroundColor:"#6ba7cf"}}>
                <Row className="no-gutters">
                    <Col style={{padding: 0}}>
                        <StaticImage src={"../images/pages/about.png"} alt="board" className='image'/>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="oddcontainer">
                        <Row className="oddcontainer">
                            <Col>
                            <h1 className="bodytext">{T(lang,{en:"About us", nl:"Over ons"})}</h1>
                                <p>{T(lang, {en:"Study Association InterSECtion is the study association of the master study Science Education and Communication (SEC) of the Delft University of Technology. InterSECtion offers and helps to maintain a network between SEC students (minor as well as master students, communication as well as education students), teachers, alumni and companies. Next to that, InterSECtion provides SEC students with opportunities for professional development, by organizing networking events or other events that helps students to develop personally as well as intellectually. Furthermore, the association is committed to improving the educational quality of the SEC programma. We do this by organizing feedback sessions with students and staying in touch with the educational committee." , 
                                             nl:"Studievereniging InterSECtion is de studievereniging van de masterstudie Science Education and Communication (SEC) aan de TU Delft. InterSECtion biedt en onderhoudt een netwerk tussen studenten (van zowel de minor als de master en van zowel de educatierichting als de communicatierichting), docenten, alumni en bedrijven. Daarnaast biedt InterSECtion de studenten ondersteuning bij hun professionele ontwikkeling door mogelijkheden te bieden om te netwerken en zich op persoonlijk en intellectueel vlak te verbreden naast de studie.  Bovendien draagt de stichting ook bij aan de onderwijskwaliteit van de opleiding, door middel van feedback sessies en het bijwonen van de vergaderingen van de onderwijscommissie." 
                                            }
                                     )}
                                </p>
                                <p>{T(lang,{en:"Next to these formal roles, InterSECtion also organizes informal activities, such as lunches, diners, drinks and study trips abroad. The aim of these activities is to maintain and strengthen the connections between communication and education students, by giving them opportunities to get to know each other better and differently apart from study-related contacts only. ",
                                            nl:"Naast deze formele taken organiseert InterSECtion ook informele activiteiten, zoals lunches, diners, borrels en een studiereis naar het buitenland (welke natuurlijk ook deels als formeel beschouwd mag worden). Het doel van deze activiteiten is om studenten de mogelijkheid te geven om een band te scheppen en te onderhouden die verder gaat dan alleen samen studeren."
                                            }
                                    )}
                                </p>
                                <p>{T(lang,{en:"InterSECtion was founded in 2008 and since then there have been 14 boards. Every board has succesfully organized a wide range of activities, like a study trip (to among others Helsinki, Gent and Valencia), a Christmas or New Year diner, an end of the year barbecue, crash cases with different companies (amongst others KPNC, SciCom NL, Connekt and Stabiplan), drinks and lunches. Apart from the minor students in the first semester, there are approximately 200 students that are eligible to attend these events. ",
                                            nl:"InterSECtion is opgericht in 2008 en sindsdien zijn er 14 besturen geweest. Elk bestuur heeft met succes verschillende vaste activiteiten georganiseerd, zoals een studiereis (naar o.a. Helsinki, Gent en Valencia), een kerst- of nieuwjaarsdiner, een eindejaarsbarbecue, crash cases met bedrijven (o.a. KPNC en SciCom NL), borrels en lunches. Buiten de minorstudenten in het eerste semester, zijn er ongeveer 200 studenten die in aanmerking komen om deel te nemen aan de evenementen van InterSECtion."
                                            }
                                    )}
                                </p>
                                <p>{T(lang,{en:"Generally speaking, the foundation ‘Study Association InterSECtion’ is managed and administered by at least three part-time students who together form the foundation’s board. Normally the board consists of a chair, a secretary and a treasurer, who change yearly around October. This is also how board 14 has been composed. ",
                                            nl:"De stichting ‘Study Association InterSECtion’ wordt normaal gesproken beheerd en bestuurd door minstens drie part-time studenten die samen het bestuur vormen. Het bestuursjaar van InterSECtion loopt officieel van november tot en met oktober het daaropvolgende jaar. Het bestuur bestaat doorgaans uit een voorzitter, secretaris en een penningmeester. Dit is ook de samenstelling van bestuur 14.                                             "
                                            }
                                    )}
                                </p>
                                <p>{T(lang,{en:"Board 14 consists of: ",
                                            nl:"Bestuur 14 is bestaande uit:  "
                                            }
                                    )}
                                </p>
                                <div className="grid-container">
                                    <div className="grid-item">{T(lang,{en:"Chair:", nl:"Voorzitter:"})}</div>
                                    {/* Add name of chair beneath */}
                                    <div className="grid-item">Annick van der Lingen </div>
                                    <div className="grid-item"></div>
                                    <div className="grid-item">{T(lang,{en:"Secretary:", nl:"Secretaris:"})}</div>
                                    {/* Add name of secretary beneath */}
                                    <div className="grid-item">Sybe Andringa</div>
                                    <div className='grid-item'></div>
                                    <div className='grid-item'>{T(lang,{en:"Treasurer:", nl:"Penningmeester"})}</div>
                                    {/* Add name of treasurer beneath */}
                                    <div className='grid-item'>Ylke van der Zwet</div>
                                    <div className='grid-item'></div>
                                </div>
                            </Col>
                        </Row> 		
            </Container>



    </LayoutBootstrap>
    )


}

export default AssocationPage

export const translationsAssociation = {
    "AssociationH1":{
        "en":"About us",
        "nl":"Over ons",
    },
    "AssociationP1": {
       "en": "Study Association InterSECtion is the study association of the master study Science Education and Communication (SEC) of the Delft University of Technology. InterSECtion offers and helps to maintain a network between SEC students (minor as well as master students, communication as well as education students), teachers, alumni and companies. Next to that, InterSECtion provides SEC students with opportunities for professional development, by organizing networking events or other events that helps students to develop personally as well as intellectually. Furthermore, the association is committed to improving the educational quality of the SEC programma. We do this by organizing feedback sessions with students and staying in touch with the educational committee. ",
       "nl": "Studievereniging InterSECtion is de studievereniging van de masterstudie Science Education and Communication (SEC) aan de TU Delft. InterSECtion biedt en onderhoudt een netwerk tussen studenten (van zowel de minor als de master en van zowel de educatierichting als de communicatierichting), docenten, alumni en bedrijven. Daarnaast biedt InterSECtion de studenten ondersteuning bij hun professionele ontwikkeling door mogelijkheden te bieden om te netwerken en zich op persoonlijk en intellectueel vlak te verbreden naast de studie.  Bovendien draagt de stichting ook bij aan de onderwijskwaliteit van de opleiding, door middel van feedback sessies en het bijwonen van de vergaderingen van de onderwijscommissie. ",
    },
}